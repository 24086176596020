import React, { useEffect, useState } from "react";
import "./App.css";
import { RetellWebClient } from "retell-client-js-sdk";
import pgImage from "./pg.jpeg";

const agentId = "f3416e439c3ac8fac8bd148de9eb143f";

const BACKEND_URL = "https://vast-falls-11703-30c4845b5657.herokuapp.com";
// const BACKEND_URL = "http://localhost:8080";

const timer_duration = 10 * 60;

// const BACKEND_URL = "http://localhost:8080";

interface RegisterCallResponse {
  callId?: string;
  sampleRate: number;
}

const webClient = new RetellWebClient();

const App = () => {
  const [isCalling, setIsCalling] = useState(false);
  const [showFeedbackForm, setShowFeedbackForm] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const [callId, setCallId] = useState<string | undefined>(undefined);
  const [timeRemaining, setTimeRemaining] = useState(timer_duration); // 10 minutes in seconds
  const [timerActive, setTimerActive] = useState(false);

  useEffect(() => {
    webClient.on("conversationStarted", () => {
      console.log("conversationStarted");
    });

    webClient.on("audio", (audio: Uint8Array) => {
      console.log("There is audio");
    });

    webClient.on("conversationEnded", ({ code, reason }) => {
      console.log("Closed with code:", code, ", reason:", reason);
      if (callId) {
        console.log("Call Ended. Call Id is", callId);
        setShowFeedbackForm(true);
      }
      setIsCalling(false);
    });

    webClient.on("error", (error) => {
      console.error("An error occurred:", error);
      setIsCalling(false);
    });
  }, [callId]);

  useEffect(() => {
    if (timerActive) {
      if (timeRemaining > 0) {
        const interval = setInterval(() => {
          setTimeRemaining(timeRemaining - 1);
        }, 1000);
        return () => clearInterval(interval);
      } else {
        webClient.stopConversation(); 
        setIsCalling(false);
        setTimerActive(false); 
      }
    }
  }, [timerActive, timeRemaining]);


  const clearFeedbackUI = () => {
    setShowFeedbackForm(false); // Hide form after fetching feedback
    setUserEmail(''); // Clear the email input field
  }
  const fetchCallFeedback = async (email: string, callId: string) => {
    console.log("Trying to fetch feedback for", email);
    try {
      const response = await fetch(BACKEND_URL + `/feedback/${callId}`, {
        method: "POST", // Assuming you might need to POST the email for feedback
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: email }), // Send email in the request
      });
      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }
    } catch (err) {
      console.error("Failed to fetch call feedback:", err);
    }
    setCallId(undefined);
  };

  const handleFeedbackButtonClick = () => {
    console.log("Handle feedback clicked");
    clearFeedbackUI()
    console.log(callId)
    if (callId && userEmail) {
      console.log("Fetching feedback");
      fetchCallFeedback(userEmail, callId);
    }
  };

  const toggleConversation = async () => {
    if (isCalling) {
      webClient.stopConversation();
      setTimerActive(false);
    } else {
      const registerCallResponse = await registerCall(agentId);
      if (registerCallResponse.callId) {
        webClient.startConversation({
          callId: registerCallResponse.callId,
          sampleRate: registerCallResponse.sampleRate,
          enableUpdate: true,
        }).catch(console.error);
        setIsCalling(true);
        setCallId(registerCallResponse.callId); // Store the callId
        setShowFeedbackForm(false);
        setTimeRemaining(timer_duration);
        setTimerActive(true);
      }
    }
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  };

  async function registerCall(agentId: string): Promise<RegisterCallResponse> {
    try {
      const response = await fetch(
        BACKEND_URL + "/register-call-on-your-server",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            agentId: agentId,
          }),
        },
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }

      const data: RegisterCallResponse = await response.json();
      return data;
    } catch (err) {
      console.error(err);
      throw new Error(err);
    }
  }

  return (
    <div className="App">
      <header className="App-header">
        <img src={pgImage} alt="PG" className="header-image" />
        <h1 style={{ fontWeight: 'bold' }}>Welcome to your <span className="orange-text">YC</span> mock interview with AI Paul Graham</h1>
        <button onClick={toggleConversation}>
          {isCalling ? "Stop" : "Start Interview"}
        </button>
        {isCalling && (
          <div>
            Time Remaining: {formatTime(timeRemaining)}
          </div>
        )}
        {showFeedbackForm && (
          <div>
            <input type="email" placeholder="Enter email" value={userEmail} onChange={(e) => setUserEmail(e.target.value)} />
            <button onClick={handleFeedbackButtonClick}>Get Feedback</button>
          </div>
        )}
      </header>
      <footer className="App-footer">
        Questions or Feedback? Email <a href="mailto:snmishra@alum.mit.edu">snmishra@alum.mit.edu</a>
      </footer>
    </div>
  );
  
};

export default App;
